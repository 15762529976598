export const getAddress = (data) => {
  const { AddressLine, City, Province, Zip } = data || {};

  // Construct full address
  let fullAddress = '';

  if (AddressLine) {
    fullAddress += AddressLine;
  }

  if (City) {
    fullAddress += `\n${City}`;
  }

  if (Province) {
    fullAddress += `, ${Province}`;
  }

  if (Zip) {
    fullAddress += `, ${Zip}`;
  }

  // Trim any extra spaces or commas
  return fullAddress.trim().replace(/,\s*$/, '');
};
