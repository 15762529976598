import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import PageTitle from '../../../../components/Layouts/Titles/PageTitle';
import ThemeColor from './Inputs/ThemeColor';
import PrivateInvoiceEmail from './Inputs/PrivateInvoiceEmail';
import PrivateInvoicePhone from './Inputs/PrivateInvoicePhone';
import PrivateInvoiceAddress from './Inputs/PrivateInvoiceAddress';
import { t } from '../../../../service/localization/i18n';

const UserPreferences = () => {
  const history = useHistory();

  const onCancel = () => {
    history.goBack();
  };

  return (
    <div className="flex justify-content-center p-3">
      <div className="card max-w-30rem w-full">
        <div className="flex flex-column gap-3">
          {/* Title */}
          <div className="text-center">
            <PageTitle>{t('User_Preferences')}</PageTitle>
          </div>

          <div className="flex flex-column gap-3">
            <ThemeColor />

            <div>
              <h5 className="text-center">{t('PrivateInvoiceDisplayOptions')}</h5>
              <PrivateInvoiceEmail />
              <PrivateInvoicePhone />
              <PrivateInvoiceAddress />
            </div>
          </div>

          {/* Action buttons */}
          <Button className="w-full" label={t('Close')} onClick={onCancel} />
        </div>
      </div>
    </div>
  );
};

export default UserPreferences;
