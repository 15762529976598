import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { dropdownScrollHeight } from '../../../../config/stylesConfig';
import { InputWrapper } from '../../../../../components/Inputs';
import { themes } from '../../../../../themes/themes';
import { setTheme } from '../../../../core/actions/core.action.creators';
import { updatePreference } from '../../../helpers/updatePreference';
import { prefsCodes } from '../../../../config/prefsCodesConfig';
import { savePreferences } from '../../../actions/preferences.action.creators';
import { localStorageKeys } from '../../../../config/localStorageKeysConfig';
import { t } from '../../../../../service/localization/i18n';

const ThemeColor = () => {
  const dispatch = useDispatch();
  const currentTheme = useSelector((state) => state.core.theme);
  const { currentUserPrefs } = useSelector((state) => state.preferences);
  const [selectedTheme, setSelected] = useState(currentTheme);

  const onThemeChange = (theme) => {
    setSelected(theme);
    dispatch(setTheme(theme));

    const displayPrefs = currentUserPrefs?.prefs?.find((i) => i.label === 'display');
    const updatedDisplayPrefs = updatePreference(displayPrefs, theme, prefsCodes.theme);

    dispatch(
      savePreferences(currentUserPrefs?.UserGuid, updatedDisplayPrefs, () => {
        const theme = updatedDisplayPrefs?.content?.find((i) => i.label === prefsCodes.theme)?.value;
        localStorage.setItem(localStorageKeys.themeKey, JSON.stringify(theme));
      })
    );
  };

  return (
    <InputWrapper label={t('Colors')}>
      <Dropdown autoFocus value={selectedTheme} options={themes} scrollHeight={dropdownScrollHeight()} onChange={(e) => onThemeChange(e.value)} />
    </InputWrapper>
  );
};

export default ThemeColor;
