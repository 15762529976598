import React from 'react';
import { Timeline } from 'primereact/timeline';
import NoteCard from '../../../NoteCard/NoteCard';
import { useNotesContext } from '../../hooks/useNotesContext';
import { t } from 'i18next';
import './NotesList.scss';

const NotesList = () => {
  const {
    notesList,
    setNotesState,
    notesState: { searchQuery },
    renderMenu = true
  } = useNotesContext();

  if (!notesList?.length) {
    return <div className="text-center px-0 md:px-5 text-xl">{t('No_notes_found')}</div>;
  }

  const onEditNote = (note) => {
    setNotesState((prevState) => ({
      ...prevState,
      noteDialog: { showDialog: true, note }
    }));
  };

  const onDeleteNote = (note) => {
    setNotesState((prevState) => ({
      ...prevState,
      deleteNoteDialog: { showDialog: true, note }
    }));
  };

  const getMenuModel = (note) => {
    return [
      {
        label: t('Edit'),
        icon: 'pi pi-pencil',
        command: () => onEditNote(note)
      },
      {
        label: t('Delete'),
        icon: 'pi pi-trash',
        command: () => onDeleteNote(note)
      }
    ];
  };

  const contentTemplate = (note) => {
    const menuModel = getMenuModel(note);
    return (
      <div className="note-card-container">
        <NoteCard note={note} menuModel={menuModel} renderMenu={renderMenu} searchQuery={searchQuery} />
      </div>
    );
  };

  const markerTemplate = () => (
    <i
      className="pi pi-bell"
      style={{
        fontSize: '1.5em',
        color: 'white',
        backgroundColor: '#689F38', // Use PrimeReact's cyan-600 color
        borderRadius: '50%',
        padding: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30px', // Adjust the width and height as needed
        height: '30px'
      }}
    ></i>
  );

  return (
    <div className="timeline-container">
      {/* Use the CSS class for styling */}
      <Timeline
        value={notesList}
        align="left"
        content={contentTemplate} // Use NoteCard as the content template
        marker={markerTemplate} // Custom marker for each event
        //  style={{ display: 'flex', justifyContent: 'center' }} // Align center
      />
    </div>
  );
};

export default NotesList;
