import React, { useRef, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { OverlayPanel } from 'primereact/overlaypanel';
import moment from 'moment';

const DateChangeArrows = () => {
  const op = useRef(null);
  const { watch, setValue } = useFormContext();
  const periodFrom = watch('PeriodFrom');
  const periodTo = watch('PeriodTo');
  const [selectedRange, setSelectedRange] = useState(null); // To track the selected quick pick range
  const [selectedMonth, setSelectedMonth] = useState(null); // To track the selected month
  const [selectedYear, setSelectedYear] = useState(moment().year()); // To track the selected year

  // Calculate date difference dynamically
  const calculateDateDifference = () => {
    if (periodFrom && periodTo) {
      const diff = moment(periodTo).diff(moment(periodFrom), 'days');
      return diff;
    }
    return 0;
  };

  // Set initial selected range based on the current date difference
  useEffect(() => {
    const initialDateDifference = moment(periodTo).diff(moment(periodFrom), 'days');
    setSelectedRange(initialDateDifference);
  }, [periodFrom, periodTo]);

  // Handlers for arrow buttons
  const onLeftArrowClick = () => {
    if (selectedMonth !== null) {
      // If a month is selected, navigate to the previous month
      const newMonth = selectedMonth === 0 ? 11 : selectedMonth - 1; // Wrap around to December if current month is January
      const newYear = selectedMonth === 0 ? selectedYear - 1 : selectedYear; // Decrement year if wrapping to December
      const newPeriodFrom = moment().month(newMonth).year(newYear).startOf('month').toDate();
      const newPeriodTo = moment().month(newMonth).year(newYear).endOf('month').toDate();
      setValue('PeriodFrom', newPeriodFrom);
      setValue('PeriodTo', newPeriodTo);
      setSelectedMonth(newMonth); // Update selected month
      setSelectedYear(newYear); // Update selected year
    } else {
      // Navigate based on day range
      const dateDifference = calculateDateDifference();
      if (periodFrom && periodTo && dateDifference > 0) {
        const newPeriodFrom = moment(periodFrom).subtract(dateDifference, 'days').toDate();
        const newPeriodTo = moment(periodTo).subtract(dateDifference, 'days').toDate();
        setValue('PeriodFrom', newPeriodFrom);
        setValue('PeriodTo', newPeriodTo);
      }
    }
  };

  const onRightArrowClick = () => {
    if (selectedMonth !== null) {
      // If a month is selected, navigate to the next month
      const newMonth = selectedMonth === 11 ? 0 : selectedMonth + 1; // Wrap around to January if current month is December
      const newYear = selectedMonth === 11 ? selectedYear + 1 : selectedYear; // Increment year if wrapping to January
      const newPeriodFrom = moment().month(newMonth).year(newYear).startOf('month').toDate();
      const newPeriodTo = moment().month(newMonth).year(newYear).endOf('month').toDate();
      setValue('PeriodFrom', newPeriodFrom);
      setValue('PeriodTo', newPeriodTo);
      setSelectedMonth(newMonth); // Update selected month
      setSelectedYear(newYear); // Update selected year
    } else {
      // Navigate based on day range
      const dateDifference = calculateDateDifference();
      if (periodFrom && periodTo && dateDifference > 0) {
        const newPeriodFrom = moment(periodFrom).add(dateDifference, 'days').toDate();
        const newPeriodTo = moment(periodTo).add(dateDifference, 'days').toDate();
        setValue('PeriodFrom', newPeriodFrom);
        setValue('PeriodTo', newPeriodTo);
      }
    }
  };

  const dateDifference = calculateDateDifference();
  const dateDifferenceLabel = dateDifference === 1 ? 'day' : 'days';

  // Handler for quick pick buttons in the OverlayPanel
  const onQuickPick = (days) => {
    const currentDate = moment(); // Get the current date
    const newPeriodTo = currentDate.toDate();
    const newPeriodFrom = currentDate.subtract(days, 'days').toDate();
    setValue('PeriodFrom', newPeriodFrom);
    setValue('PeriodTo', newPeriodTo);
    setSelectedRange(days); // Set selected range
    setSelectedMonth(null); // Reset selected month
    setSelectedYear(moment().year()); // Reset selected year to current year
    op.current.hide();
  };

  // Handler for filtering by month
  const onMonthPick = (monthIndex) => {
    const year = moment().year();
    const newPeriodFrom = moment().month(monthIndex).year(year).startOf('month').toDate();
    const newPeriodTo = moment().month(monthIndex).year(year).endOf('month').toDate();
    setValue('PeriodFrom', newPeriodFrom);
    setValue('PeriodTo', newPeriodTo);
    setSelectedMonth(monthIndex); // Set selected month
    setSelectedYear(year); // Set selected year
    setSelectedRange(null); // Reset selected range
    op.current.hide();
  };

  // Array of month names
  const months = moment.months();

  // Determine the label to display based on selected range or month
  const displayLabel = selectedMonth !== null ? `${months[selectedMonth]} ${selectedYear}` : `${dateDifference} ${dateDifferenceLabel}`;

  return (
    <div className="flex justify-content-between align-items-center w-full">
      {/* Left Arrow */}
      <Button
        icon="pi pi-chevron-left"
        rounded
        text
        tooltip={`Previous ${displayLabel}`}
        tooltipOptions={{ position: 'top' }}
        onClick={onLeftArrowClick}
      />

      {/* Date Difference or Selected Month */}
      <div className="pointer" type="button" onClick={(e) => op.current.toggle(e)}>
        {displayLabel}
      </div>

      {/* Right Arrow */}
      <Button
        icon="pi pi-chevron-right"
        rounded
        text
        tooltip={`Next ${displayLabel}`}
        tooltipOptions={{ position: 'top' }}
        onClick={onRightArrowClick}
      />

      {/* Overlay Panel with Quick Pick Buttons */}
      <OverlayPanel ref={op} style={{ maxWidth: '400px' }}>
        <div>
          <h6 className="text-center">Select Date Range</h6>
          <div className="flex flex-wrap justify-content-center gap-3">
            <Button
              form="cash-flow-filters-form"
              rounded
              text={selectedRange !== 1 || selectedMonth !== null}
              label="1 Day"
              onClick={() => onQuickPick(1)}
            />
            <Button
              form="cash-flow-filters-form"
              rounded
              text={selectedRange !== 7 || selectedMonth !== null}
              label="7 Days"
              onClick={() => onQuickPick(7)}
            />
            <Button
              form="cash-flow-filters-form"
              rounded
              text={selectedRange !== 14 || selectedMonth !== null}
              label="14 Days"
              onClick={() => onQuickPick(14)}
            />
            <Button
              form="cash-flow-filters-form"
              rounded
              text={selectedRange !== 30 || selectedMonth !== null}
              label="30 Days"
              onClick={() => onQuickPick(30)}
            />
            <Button
              form="cash-flow-filters-form"
              rounded
              text={selectedRange !== 60 || selectedMonth !== null}
              label="60 Days"
              onClick={() => onQuickPick(60)}
            />
            <Button
              form="cash-flow-filters-form"
              rounded
              text={selectedRange !== 90 || selectedMonth !== null}
              label="90 Days"
              onClick={() => onQuickPick(90)}
            />
            <Button
              form="cash-flow-filters-form"
              rounded
              text={selectedRange !== 180 || selectedMonth !== null}
              label="180 Days"
              onClick={() => onQuickPick(180)}
            />
            <Button
              form="cash-flow-filters-form"
              rounded
              text={selectedRange !== 365 || selectedMonth !== null}
              label="365 Days"
              onClick={() => onQuickPick(365)}
            />
          </div>

          <Divider />

          <div className="flex flex-wrap justify-content-center gap-3">
            {months.map((month, index) => (
              <Button
                key={month}
                style={{ width: '100px' }}
                form="cash-flow-filters-form"
                rounded
                text={selectedMonth !== index}
                label={month}
                onClick={() => onMonthPick(index)}
              />
            ))}
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default DateChangeArrows;
