import React from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'primereact/button';

import { useClaimsListContext } from '../../../hooks/useClaimsListContext';
import { getClaimReport } from '../../../../../../claims/actions/claims.action.creators';
import { dataExportToast } from '../../../../../../common/components/toastMessages';
import { setToastMessage } from '../../../../../../core/actions/core.action.creators';
import { t } from '../../../../../../../service/localization/i18n';

const ExportButton = (props) => {
  const dispatch = useDispatch();
  const { state } = useClaimsListContext();

  const onExport = async () => {
    const response = await getClaimReport({ sortby: state.sortby, query: state.query });
    if (response) dispatch(setToastMessage(dataExportToast(response)));
  };

  return <Button {...props} text icon="pi pi-file-export" tooltip={t('Export')} tooltipOptions={{ position: 'top' }} onClick={onExport} />;
};

export default ExportButton;
