import React from 'react';
import { useController } from 'react-hook-form';

import Editor from './Editor';
import InputWrapper from '../InputWrapper/InputWrapper';

import { decodeTextContent } from '../../../modules/utils/decodeText';
import { t } from '../../../service/localization/i18n';

const NoteEditorWithFormControl = ({ control, id, name, editorStyle, autoFocus }) => {
  const label = t('Note');

  const charLimit = 2000;

  const {
    field,
    formState: { errors }
  } = useController({
    name,
    control,
    rules: {
      validate: (value) => validation(value)
    }
  });

  const validation = (value) => {
    const textValue = decodeTextContent(value)?.trim();
    if (textValue?.length > charLimit) {
      return `The text exceeds the maximum limit of ${charLimit} characters.`;
    }
    return (!!value && value !== '\n') || t('Mandatory_field.1');
  };

  return (
    <InputWrapper
      name={name}
      label={label}
      required
      showCounter
      inputValue={decodeTextContent(field.value)?.trim() || ''}
      countLimit={charLimit}
      errorMessage={errors?.[name]?.message}
      style={{ height: 'auto', minHeight: '315px' }}
    >
      <Editor
        id={id || field.name}
        name={field.name}
        value={field.value}
        autoFocus={autoFocus}
        style={editorStyle || { height: '200px' }}
        onTextChange={(e) => field.onChange(e.htmlValue)}
      />
    </InputWrapper>
  );
};

export default NoteEditorWithFormControl;
