import { coreInitialState } from '../core/reducers/core.reducer';
import { claimInitialState } from '../claims/reducers/claims.reducer';
import { patientsInitialState } from '../patients/reducers/patients.reducer';
import { userInitialState } from '../auth/reducers/auth.reducer';
import { clinicInitialState } from '../clinic/reducers/clinic.reducer';
import { prefsInitialState } from '../preferences/reducers/preferences.reduser';
import { dashboardInitialState } from '../dashboard/reducers/dashboard.reducer';
import { practitionerInitialState } from '../practitioners/reducers/practitioners.reducer';
import { uploadInitialState } from '../mailbox/reducers/upload.reducer';
import { rolesInitialState } from '../roles/reducers/roles.reducer';

const initialReducers = {
  user: userInitialState,
  patients: patientsInitialState,
  practitioners: practitionerInitialState,
  reports: {},
  core: coreInitialState,
  dashboard: dashboardInitialState,
  claims: claimInitialState,
  clinic: clinicInitialState,
  preferences: prefsInitialState,
  upload: uploadInitialState,
  roles: rolesInitialState
};

export default initialReducers;
