import React from 'react';
import { useSelector } from 'react-redux';

import { Skeleton } from 'primereact/skeleton';

import { inputs } from '../../claimDetailsHelpers/inputs';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { getDefaultPrefs } from '../../../../helpers/getDefaultPrefs';
import { prefsCodes } from '../../../../../config/prefsCodesConfig';
import { getAddress } from '../../../../../utils/getAddress';
import { t } from '../../../../../../service/localization/i18n';

const PayeeSection = ({ data, isFetching }) => {
  const clinic = useSelector((state) => state.clinic.details);
  const members = useSelector((state) => state.clinic.members);
  const preferences = useSelector((state) => state.preferences.currentUserPrefs);
  const payTo = data[inputs().payTo.name] ? data[inputs().payTo.name] : {};

  const defaultPrefs = preferences?.prefs?.find((i) => i.label === 'default');
  // Extract preferences using the helper function
  const emailPreference = getDefaultPrefs(defaultPrefs?.content, prefsCodes.privateInvoiceEmail);
  const phonePreference = getDefaultPrefs(defaultPrefs?.content, prefsCodes.privateInvoicePhone);
  const addressPreference = getDefaultPrefs(defaultPrefs?.content, prefsCodes.privateInvoiceAddress);

  // Helper function to get data by preference (returns clinic or member data)
  const getContactInfoByPreference = (preference) => {
    if (!preference) return null;
    if (clinic?.DCNGuid === preference) return clinic;
    return members?.find((member) => member.UserGuid === preference) || null;
  };

  // Fetch the preferred data (email, phone, address)
  const emailContactInfo = getContactInfoByPreference(emailPreference);
  const phoneContactInfo = getContactInfoByPreference(phonePreference);
  const addressContactInfo = getContactInfoByPreference(addressPreference);

  // Extract the individual fields from the fetched contact info
  const contactEmail = emailContactInfo?.Email || '';
  const contactPhone = phoneContactInfo?.Phone || '';
  const contactAddress = getAddress(addressContactInfo) || '';

  return (
    <>
      <div className="company-name">{t('Payee').toUpperCase()}</div>

      {isFetching ? (
        <>
          <Skeleton width="10rem" className="mb-2" />
          <Skeleton width="10rem" className="mb-2" />
          <Skeleton width="10rem" className="mb-2" />
        </>
      ) : (
        <div id={elementIDs.payeeSection}>
          <div className="flex col-12 p-0 mb-3 font-bold">
            {payTo?.LastName ? `${payTo?.LastName?.toUpperCase()}, ${payTo?.FirstName?.toUpperCase()}` : payTo?.ClinicName}
          </div>

          <div className="addressLayoutWrap">
            <div className="addressWrap mb-0">
              <div className="flex flex-column">
                {contactAddress && <div>{contactAddress}</div>}
                {contactEmail && <div>{contactEmail}</div>}
                {contactPhone && <div>{contactPhone}</div>}
              </div>
            </div>
          </div>

          {/* GST number */}
          {payTo.GSTNumber && <div>{`${t('GST')} # ${payTo.GSTNumber}`}</div>}
        </div>
      )}
    </>
  );
};

export default PayeeSection;
