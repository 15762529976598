import { useDispatch, useSelector } from 'react-redux';
import { updatePreference } from '../../../helpers/updatePreference';
import { savePreferences } from '../../../actions/preferences.action.creators';

export const usePrivateInvoicePrefs = () => {
  const dispatch = useDispatch();
  const { currentUserPrefs } = useSelector((state) => state.preferences);

  const savePrefs = (value, prefsCode) => {
    const defaultPrefs = currentUserPrefs?.prefs?.find((i) => i.label === 'default');
    const updatedDisplayPrefs = updatePreference(defaultPrefs, value, prefsCode);
    dispatch(savePreferences(currentUserPrefs?.UserGuid, updatedDisplayPrefs));
  };

  const getPreferenceValue = (prefsCode) => {
    const defaultPrefs = currentUserPrefs?.prefs?.find((i) => i.label === 'default');
    return defaultPrefs?.content?.find((i) => i.label === prefsCode);
  };

  return { savePrefs, getPreferenceValue };
};
