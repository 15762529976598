import React, { useRef } from 'react';

import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import HighlightSearchQuery from '../../../Misc/HighlightSearchQuery/HighlightSearchQuery';
import SafeHtmlContent from '../../../Misc/SafeHTMLContent/SafeHTMLContent';

import { decodeText } from '../../../../modules/utils/decodeText';
import { t } from '../../../../service/localization/i18n';
import moment from 'moment';

// interface INoteCard {
//   note: {
//     Title: string,
//     Comments: string,
//     NoteDate: Date,
//     Author: string,
//     NoteGuid: string,
//     EntityGuid: string, // PatientGuid for patient notes or ClaimGuid for invoices notes
//     ParentGuid: string,
//     DCNGuid: string,
//     DoctorGuid: string,
//     Thread?: string,
//     Adressee?: any,
//     DateCreated?: Date,
//   },
//   menuModel: {
//     label: string,
//     command: () => void
//     icon?: string
//   }[]
//   searchQuery?: string // need for highlight keywords in search results
//   hideTitle?: boolean
//   hideCard?: boolean
// }

const NoteCard = ({ note, hideCard, ...props }) => {
  const menuRef = useRef(null);
  const dateCreated = moment(note?.NoteDate).format('MM/DD/YYYY');

  return (
    <div className={hideCard ? '' : 'card'}>
      <div className="flex flex-column gap-4">
        <div className="flex flex-column gap-2">
          <div className="flex justify-content-between align-items-center gap-3" style={{ minHeight: '32px' }}>
            <div className="flex align-items-center gap-2">
              <div className="text-500 text-left pt-1">{dateCreated}</div>
              <div
                style={{
                  width: '5px',
                  height: '5px',
                  borderRadius: '50%',
                  backgroundColor: 'grey'
                }}
              ></div>
              <div className="text-500 text-left pt-1">{`${t('Author')}: ${note?.Author}`}</div>
            </div>
            {props.menuModel && props.renderMenu && (
              <>
                <Menu model={props.menuModel} popup ref={menuRef} id="note_action" />
                <Button
                  className="p-button-rounded p-button-text"
                  icon="pi pi-ellipsis-v"
                  onClick={(event) => menuRef.current.toggle(event)}
                  aria-controls="note_action"
                  aria-haspopup
                />
              </>
            )}
          </div>

          {props.hideTitle ? null : (
            <h4 className="m-0" style={{ fontSize: '1rem', textAlign: 'left' }}>
              <HighlightSearchQuery query={props.searchQuery || ''} text={decodeText(note?.Title)} />
            </h4>
          )}
        </div>

        <SafeHtmlContent htmlContent={decodeText(note?.Comments)} />
      </div>
    </div>
  );
};

export default NoteCard;
