import SafeHtmlContent from '../../../components/Misc/SafeHTMLContent/SafeHTMLContent';
import ClaimService from '../../../service/ClaimService';
import { t } from '../../../service/localization/i18n';

export const warnToastBasicLifeTime = 25000;

export const commonToast = (ref, message) => {
  let basicTitile;
  let basicLifeTime;
  switch (message?.type) {
    case 'success':
      basicTitile = t('Success');
      basicLifeTime = 10000;
      break;

    case 'warn':
      basicTitile = t('Warning');
      basicLifeTime = warnToastBasicLifeTime;
      break;

    case 'info':
      basicTitile = t('Info');
      basicLifeTime = 20000;
      break;

    case 'error':
      basicTitile = t('Error');
      basicLifeTime = 60000;
      break;

    default:
      break;
  }

  ref.current.show({
    sticky: message?.lifeTime === 'sticky',
    life: message?.lifeTime ? message?.lifeTime : basicLifeTime,
    severity: message?.type,
    summary: message?.title ? message?.title : basicTitile,

    detail: typeof message?.message === 'string' ? <SafeHtmlContent htmlContent={message?.message} /> : message?.message
  });
};

export const dataExportToast = (data) => {
  const persitsReport = async (DCNGuid, fileName) => {  
    const result = await ClaimService.shareReport(DCNGuid, fileName);
    if (result) alert('Report shared');
    return false;  
  };
  return {
    type: 'info',
    title: 'You data export is ready',
    position: 'top-right',
    lifeTime: 100000,
    message: (
      <div>
        <a href={data.LinkToCsv} style={{ textDecoration: 'underline' }} target="_blank">
          Report (CSV)
        </a>
        <br />
        <a href={data.LinkToXls} style={{ textDecoration: 'underline' }} target="_blank">
          Report (Excel)
        </a>
        <br/>
        <a href="#" style={{ textDecoration: 'underline' }}
          onClick={(event) => {
            event.preventDefault();
            persitsReport(data.DCNGuid, data.LinkToShare);
          }}>
          Share on <strong>Daysheet Uploads</strong>
        </a>
      </div>
    )
  };
};
