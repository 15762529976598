export const prefsCodes = {
  // default
  presetPractitioner: '120', // bool	Use preset practitioner	default
  defaultPractitioner: '121', //text Default practitioner	default

  presetRural: '117', // bool	Use preset rural code	default
  defaultRural: '17', //text Default rural code	default

  presetPayee: '100', //	bool	Use preset payee number	default
  defaultPayee: '101', //	text	Default payee number	default

  presetLocation: '102', //  bool	Use preset location code	default
  defaultLocation: '103', //	text	Default location code	default

  presetEmergency: '104', //	bool	Use preset emergency flag	default
  defaultEmergency: '105', //	bool	Default emergency flag	default

  presetTravel: '106', //	bool	Use preset immediate travel flag	default
  defaultTravel: '107', //	bool	Default immediate travel flag	default

  presetCCFPP: '151', //	bool	Use preset immediate ccfpp flag	default
  defaultCCFPP: '152', //	bool	Default immediate ccfpp flag	default

  presetFacilityList: '20', //	string	Preset facility list	default
  presetFacilityNum: '122', //	string	Preset facility number	default

  presetReferral: '108', //	bool	Use preset referral	default
  defaultReferralDirection: '109', //	text	Default referral direction	default
  defaultReferralNumber: '110', //	text	Default referral number	default
  defaultReferralFullName: '111', //	text	Default referral full name	default

  // Create private invoice
  presetPayTo: '136', //	bool	Use preset payTo flag	default
  defaultPayTo: '137', //	bool	Default payTo flag	default

  // Create WSBC Report
  lockWSBCReportCatalog: '66',

  consecutiveTime: '96',

  // Payor (Invoice type)
  invoiceType: '138',

  // eClaim
  eClaimPayTo: '153',
  eClaimLocation: '154',
  pbcLocation: '155',

  // Private Invoice
  privateInvoiceEmail: '170',
  privateInvoicePhone: '171',
  privateInvoiceAddress: '172',

  // display
  language: '1',
  theme: '666',
  dataTable: '42',

  // system
  summaryViewLastModified: '18', // Summary View LastModified
  clinicLogo: '13' // Logo of current clinic
};
