import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button } from 'primereact/button';
import Date from './Inputs/Date';
import Title from './Inputs/Title';
import Note from './Inputs/Note';
import Author from './Inputs/Author';

import { encodeText } from '../../../../modules/utils/decodeText';
import { getDefaultData } from './helpers/getDefaultData';
import { inputs } from './helpers/inputs';
import { t } from '../../../../service/localization/i18n';

// interface {
//   note: {
//     Title: string,
//     Comments: string,
//     NoteDate: Date,
//     Author: string,
//     NoteGuid: string,
//     EntityGuid: string, // PatientGuid for patient notes or ClaimGuid for invoices notes
//     ParentGuid: string,
//     DCNGuid: string,
//     DoctorGuid: string,
//     Thread?: string,
//     Adressee?: any,
//   } | undefined,
//   entityGuid: string, // PatientGuid for patient notes or ClaimGuid for invoices notes
//   onSubmit: (note) => void,
//   onCancel: () => void
// }

const CreateNote = ({ note, entityGuid, onSubmit, onCancel }) => {
  const isNew = !note;
  const methods = useForm({ defaultValues: getDefaultData({ note, entityGuid }) });

  const onSubmitData = async (data) => {
    const formattedData = {
      ...data,
      [inputs.title.name]: encodeText(data[[inputs.title.name]]),
      [inputs.note.name]: encodeText(data[[inputs.note.name]])
    };

    await onSubmit(formattedData);
    onCancel();
  };

  const onHide = () => {
    methods.reset();
    onCancel();
  };

  return (
    <FormProvider {...methods}>
      <form id="createNote" className="h-full px-3" onSubmit={methods.handleSubmit(onSubmitData)}>
        <Title />
        <Note />
        <div className="flex flex-column sm:flex-row gap-0 sm:gap-5">
          <Date />
          <Author />
        </div>

        <div className="flex justify-content-end gap-3">
          {methods.formState.isDirty ? (
            <>
              <Button className="p-button-outlined w-full md:w-auto" type="button" label={t('Cancel')} onClick={onHide} />
              <Button
                className="w-full md:w-auto"
                label={isNew ? t('Create') : t('Save_and_Close')}
                type="submit"
                form="createNote"
                loading={methods.formState.isSubmitting}
              />
            </>
          ) : (
            <Button className="w-full md:w-auto" type="button" label={t('Close')} onClick={onHide} />
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default CreateNote;
