import React, { useEffect, useState, useMemo } from 'react';
import { Timeline } from 'primereact/timeline';
import { Skeleton } from 'primereact/skeleton';
import CommonCard from '../../../common/components/CommonCard';
import { getCloseOffDays } from '../../../../service/Lookup';
import moment from 'moment-timezone';
import cx from 'classnames';

import './CloseOff.scss';

const CloseOffCard = () => {
  const PST_TIMEZONE = 'America/Los_Angeles';

  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const currentDate = moment().tz(PST_TIMEZONE).startOf('day');

  const labels = {
    C: 'Close-off',
    R: 'Remittance',
    P: 'Payment'
  };

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getCloseOffDays();
      setDates(response);
    } catch (err) {
      setError('Failed to fetch Teleplan Cut-Off Dates');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const result = useMemo(() => {
    // Group dates into cycles of three (C, R, P)
    const cycles = [];
    for (let i = 0; i < dates.length; i += 3) {
      const cycle = dates.slice(i, i + 3);
      cycles.push(cycle);
    }

    // Get current and next months
    const currentMonth = currentDate.month();
    const nextMonth = currentDate.clone().add(1, 'month').month();

    // Filter cycles by month
    const currentMonthCycles = cycles.filter((cycle) => moment(cycle[0].date).tz(PST_TIMEZONE).month() === currentMonth);

    const nextMonthCycles = cycles.filter((cycle) => moment(cycle[0].date).tz(PST_TIMEZONE).month() === nextMonth);

    let firstPart = [];
    let secondPart = [];

    if (currentMonthCycles.length >= 2) {
      const firstCycle = currentMonthCycles[0];
      const secondCycle = currentMonthCycles[1];
      const remittanceDate = moment(secondCycle[1].date).tz(PST_TIMEZONE).startOf('day');

      if (currentDate.isBefore(remittanceDate, 'day')) {
        // Before Remittance date of second cycle
        firstPart = firstCycle;
        secondPart = secondCycle;
      } else {
        // On or after Remittance date of second cycle
        firstPart = secondCycle;
        secondPart = nextMonthCycles.length > 0 ? nextMonthCycles[0] : [];
      }
    } else if (currentMonthCycles.length === 1) {
      firstPart = currentMonthCycles[0];
      secondPart = nextMonthCycles.length > 0 ? nextMonthCycles[0] : [];
    } else {
      // No cycles in current month
      firstPart = nextMonthCycles.length > 0 ? nextMonthCycles[0] : [];
      secondPart = nextMonthCycles.length > 1 ? nextMonthCycles[1] : [];
    }

    // Combine and process dates
    const allDates = [...firstPart, ...secondPart];
    allDates.forEach((item) => {
      const itemDate = moment(item.date).tz(PST_TIMEZONE).startOf('day');
      const isBefore = itemDate.isBefore(currentDate, 'day');
      const isSameDay = itemDate.isSame(currentDate, 'day');
      item.icon = isBefore || isSameDay ? 'pi pi-check' : '';
      item.iconClassName = isBefore || isSameDay ? `custom-marker-success_2` : 'custom-marker-disable';
      item.isBefore = isBefore;
    });

    // Calculate days away for the first upcoming date
    const futureDates = allDates.filter((item) => !item.isBefore);
    if (futureDates.length > 0) {
      const daysAway = moment(futureDates[0].date).tz(PST_TIMEZONE).startOf('day').diff(currentDate, 'days');
      futureDates[0].daysAway = daysAway;
    }

    return {
      firstPart,
      secondPart
    };
  }, [dates, currentDate]);

  const opposite = (item) => labels[item.value];

  const content = (item) => {
    if (loading) {
      return <Skeleton height="28px" width="78px" />;
    }

    const isCloseOff = item.value === 'C';
    const now = moment().tz(PST_TIMEZONE);
    const deadline = isCloseOff
      ? moment(item.date).tz(PST_TIMEZONE).set({ hour: 19, minute: 0, second: 0 }) // 7 PM PST
      : moment(item.date).tz(PST_TIMEZONE).endOf('day'); // End of the day

    const hoursLeft = deadline.diff(now, 'hours');
    const isToday = item.daysAway === 0;
    const dayText = item.daysAway === 1 ? 'day' : 'days';
    const message = isToday ? 'Today' : `${item.daysAway} ${dayText} away`;

    return (
      <div className="flex flex-column gap-1 h-2rem">
        {/* Date and Message Section */}
        <div className="flex flex-column sm:flex-row gap-1">
          <span>{moment(item.date).format('MMM D, YYYY')}</span>
          {isToday && isCloseOff && <div className="p-error">{`(${message})`}</div>}
        </div>

        {/* Close-Off Time Section */}
        {isCloseOff && <div>{`7 pm PST ${isToday ? `(in ${hoursLeft} hrs)` : ''}`}</div>}

        {/* Days Away Message for Non-Today, Non-Close-Off Items */}
        {!isToday && !isCloseOff && item.daysAway !== undefined && (
          <div className={cx(isToday ? 'p-error' : 'font-bold text-green-500')}>{message}</div>
        )}
      </div>
    );
  };

  const marker = (item) => (
    <span className={item.iconClassName}>
      <i className={cx(item.icon, 'text-sm')} />
    </span>
  );

  if (error) {
    return (
      <CommonCard title="Teleplan Cut-Off Dates">
        <div className="p-error text-center text-lg">{error}</div>
      </CommonCard>
    );
  }

  return (
    <CommonCard title="Teleplan Cut-Off Dates">
      <div className="flex flex-column gap-3 mb-4">
        {['firstPart', 'secondPart'].map((part) => (
          <Timeline
            key={part}
            className="closeOffTimeline"
            value={result[part]}
            layout="horizontal"
            align="top"
            content={content}
            opposite={opposite}
            marker={marker}
          />
        ))}
      </div>
    </CommonCard>
  );
};

export default CloseOffCard;
