import axios from 'axios';
import { store } from '../../../store';
import { setAuthHeader } from '../../../config';
import { setToastMessage } from '../../core/actions/core.action.creators';
import { getApiBase } from '../../../config';
import { t } from '../../../service/localization/i18n';
import { logsDefaultFilters } from '../helpers/defaultValues';
import { calculateFirst } from '../../utils/calculate';
import { dataExportToast } from '../../common/components/toastMessages';

export const getUserLogs = (payload) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  const { pageSize = 20, page = 1, userGuid } = payload;

  const baseUrl = `${getApiBase()}/reports/${DCNGuid}/audit/users/${userGuid}/${page}/${pageSize}`;
  const url = new URL(baseUrl);

  return axios
    .get(url)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log('!!!getUserLogs', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const getPatientLogs = (payload) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  const { pageSize = 20, page = 1, patientGuid } = payload;

  const baseUrl = `${getApiBase()}/reports/${DCNGuid}/audit/patients/${patientGuid}/${page}/${pageSize}`;
  const url = new URL(baseUrl);

  return axios
    .get(url)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log('!!!getPattientLogs', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const getAllLogs = (params) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  const { pageSize = 20, page = 1, sortby = 'ActionDate_desc', query = logsDefaultFilters } = params;
  const queryParams = new URLSearchParams();
  sortby && queryParams.set('sortby', sortby);
  query && queryParams.set('q', JSON.stringify(query));

  const baseUrl = `${getApiBase()}/reports/${DCNGuid}/audit/clinic/${DCNGuid}/${page}/${pageSize}/`;
  const url = new URL(baseUrl);
  url.search = queryParams.toString();

  return (
    axios
      .get(url.toString())
      .then((results) => ({
        ...results.data,
        query: results.data.query ?? JSON.parse(results.data.query),
        first: calculateFirst({
          page: results.data.page,
          pageSize: results.data.pageSize
        })
      }))
      // .then((results) => {
      //   return results.data;
      // })
      .catch((error) => {
        console.log('!!!geAllLogs', error);
        store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
      })
  );
};

export const getLogsReport = (params) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  const { query } = params;
  const queryParams = new URLSearchParams();
  //sortby && queryParams.set('sortby', sortby);
  query && queryParams.set('q', JSON.stringify(query));

  const baseUrl = `${getApiBase()}/reports/${DCNGuid}/audit/report/?${query}`;
  const url = new URL(baseUrl);
  url.search = queryParams.toString();

  return axios
    .get(url.toString())
    .then((results) => {
      store.dispatch(setToastMessage(dataExportToast(results.data)));
    })
    .catch((error) => {
      console.log('!!!geLogsReport', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};
