import React from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import AddressFormLayout from './AddressFormLayout';
import { validateCanadianZip } from '../../../regex/regex';
import { provinces_list } from '../../contants';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';
import * as yup from 'yup';
import { elementIDs } from '../../../config/elementIDsConfig';

const CommonAddressForm = ({ formikInitValues, header, visible, onClose, onSave, showInDialog = false, isFetching, additionalInputs }) => {
  const { isMobile } = useSelector((state) => state.core.window);

  const initValues = () => {
    const formattedProvince =
      formikInitValues?.Province?.length > 2 ? provinces_list.find((i) => i.label === formikInitValues?.Province)?.value : formikInitValues?.Province;

    // [KS] added new attribute in the object (AddressOnly) - when included in the request body then only four fields are updated : "AddressLine", "City","Province", "Zip"; also body shall contain DCNGuid and DoctorGuid.  All other feilds will be ignored so it is safe to omit them in request
    const common = {
      DCNGuid: formikInitValues?.DCNGuid || '',
      AddressLine: formikInitValues?.AddressLine?.trim() || '',
      Province: formattedProvince || 'BC',
      City: formikInitValues?.City || '',
      Zip: formikInitValues?.Zip || '',
      AddressOnly: true
    };

    const init = formikInitValues?.DoctorGuid
      ? { ...common, DoctorGuid: formikInitValues?.DoctorGuid }
      : formikInitValues?.PatientGuid
      ? { ...common, PatientGuid: formikInitValues?.PatientGuid }
      : common;

    return init;
  };

  const onSubmit = (data) => {
    onSave(data, onClose);
  };

  const validationSchema = yup.object().shape({
    Province: yup.string().required(t('Mandatory_field.1')),
    Zip: yup.string().matches(validateCanadianZip, t('Invalid_entry'))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues(),
    validationSchema,
    onSubmit
  });

  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  const onCancel = () => {
    onClose();
    formik.resetForm();
  };

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const addressForms = (
    <form onSubmit={formik.handleSubmit} className="p-fluid">
      {additionalInputs && additionalInputs}
      <AddressFormLayout formik={formik} getFormErrorMessage={getFormErrorMessage} />

      <div className={cx('flex col-12 p-0 align-items-end stickyActionButtons', isMobile ? '' : 'mt-4')}>
        <div className="field flex col-12 p-0">
          {formik.dirty && (
            <>
              <Button id={elementIDs.dialogSave} label={t('Save')} loading={isFetching} type="submit" />
              <Button id={elementIDs.dialogCancel} label={t('Cancel')} onClick={onCancel} className="p-button-text" type="button" />
            </>
          )}

          {!formik.dirty && <Button id={elementIDs.dialogClose} label={t('Close')} onClick={onCancel} type="button" style={{ zIndex: '1' }} />}
        </div>
      </div>
    </form>
  );

  return (
    <>
      {showInDialog ? (
        <Dialog
          id={elementIDs.editPayeeAndPayerDialog}
          header={header}
          visible={visible}
          blockScroll
          contentClassName="pb-0"
          breakpoints={{ '1366px': '50vw', '960px': '70vw', '768px': '90vw' }}
          style={{ width: '25vw' }}
          onHide={onClose}
          baseZIndex={999999}
        >
          {addressForms}
        </Dialog>
      ) : (
        <>{addressForms}</>
      )}
    </>
  );
};

export default CommonAddressForm;
