import React, { useEffect, useRef, useState } from 'react';
import DOMPurify from 'dompurify';
import { Dialog } from 'primereact/dialog';
import './SafeHTMLContent.scss';

const SafeHtmlContent = ({ htmlContent, ...props }) => {
  const contentRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  useEffect(() => {
    if (contentRef.current) {
      // Sanitize the HTML content
      const cleanHtml = DOMPurify.sanitize(htmlContent);
      contentRef.current.innerHTML = cleanHtml;

      // Attach click event to images
      const images = contentRef.current.querySelectorAll('img');
      images.forEach((img) => {
        img.addEventListener('click', () => {
          setSelectedImage(img.src); // Set the source of the clicked image
          setVisible(true); // Show the dialog
        });
      });
    }
  }, [htmlContent]);

  // Close the dialog
  const closeDialog = () => {
    setVisible(false);
    setSelectedImage('');
  };

  return (
    <>
    <div className="safe-html-content" {...props} ref={contentRef}></div>
    <Dialog header="Image" visible={visible} onHide={closeDialog} style={{ width: '80vw' }}>
      {selectedImage && <img src={selectedImage} alt="Enlarged" style={{ width: '100%', height: 'auto' }} />}
    </Dialog>
    </>
  );
};

export default SafeHtmlContent;
