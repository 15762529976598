import React from 'react';
import { Dialog } from 'primereact/dialog';
import PatientForm from '../../../modules/patients/components/PatientForm/PatientForm';
import { t } from '../../../service/localization/i18n';

// type PatientFormDialogProps = DialogProps & {
//     onEdit?: (patient) => void; // onEdit it is a callback function that will be called after the form is successfully edited and return updated patient object
//     onCreate?: (patient) => void; // onCreate it is a callback function that will be called after the form is successfully created and return created patient object
// }

const PatientFormDialog = ({ onEdit, onCreate, ...props }) => {
  return (
    <Dialog
      {...props}
      header={`${t('Edit_Profile')}`}
      breakpoints={{
        '1800px': '60vw',
        '1700px': '65vw',
        '1600px': '70vw',
        '1500px': '75vw',
        '1400px': '80vw',
        '1300px': '87vw',
        '1200px': '95vw',
        '960px': '95vw'
      }}
      style={{ width: '55vw', minHeight: '40vh', ...props.style }}
    >
      <div style={{ marginTop: '-1.5rem' }}>
        <PatientForm showInCard={false} showPrompt={false} showInDialog isEdit onClose={props.onHide} onEdit={onEdit} onCreate={onCreate} />
      </div>
    </Dialog>
  );
};

export default PatientFormDialog;
