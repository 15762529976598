import React from 'react';
import { getRedirectUrl } from './config';
import { sendErrorReport } from '../src/service/ClinicService';
import { RouteTrackerContext } from './RouteTrackerContext';


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.appmode = props.appmode;
    this.DCNGuid = props.DCNGuid;
    this.Route = props.Route;
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { previousUrl } = this.context; // Get the previous URL from context

    const errInfo = {
      DCNGuid: this.DCNGuid || '00000000-0000-0000-0000-000000000000', // Default value if undefined
      Route: this.Route || 'unknown-route', // Default value if undefined
      Details: JSON.stringify({
        message: error.message || 'Unknown error', // Default message if undefined
        stack: error.stack || 'No stack trace available',
        componentStack: errorInfo.componentStack || 'No component stack available',
      }),
      PreviousUrl: previousUrl || 'No previous URL' // Use the previous URL from context
    };
    console.error('ErrorBoundary Error:', errInfo);
    sendErrorReport(errInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

ErrorBoundary.contextType = RouteTrackerContext; // Bind the context to ErrorBoundary

export default ErrorBoundary;