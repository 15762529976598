import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { InputWrapper } from '../../../../../components/Inputs';
import { Dropdown } from 'primereact/dropdown';
import { dropdownScrollHeight } from '../../../../config/stylesConfig';
import { getAddress } from '../../../../utils/getAddress';
import { prefsCodes } from '../../../../config/prefsCodesConfig';
import { usePrivateInvoicePrefs } from '../hooks/usePrivateInvoicePrefs';
import { getPractitionerNameWithSpec } from '../../../../utils/getPractitioner';
import { t } from '../../../../../service/localization/i18n';

const PrivateInvoiceAddress = () => {
  const clinic = useSelector((state) => state.clinic.details);
  const members = useSelector((state) => state.clinic.members);
  const { savePrefs, getPreferenceValue } = usePrivateInvoicePrefs();

  const options = useMemo(() => {
    const practitioners =
      members?.map((member) => ({
        name: getPractitionerNameWithSpec(member),
        address: getAddress(member),
        preferenceValue: member.UserGuid
      })) || [];

    return [
      { name: 'None', address: '', preferenceValue: '' },
      { name: 'Clinic', address: getAddress(clinic), preferenceValue: clinic?.DCNGuid },
      ...practitioners
    ];
  }, [clinic, members]);

  const [value, setValue] = useState(options[0]);

  useEffect(() => {
    const prefsValue = getPreferenceValue(prefsCodes.privateInvoiceAddress);

    if (!prefsValue) {
      setValue(options[0]);
    } else {
      const initValue = options.find((option) => option.preferenceValue === prefsValue.value) || options[0];
      setValue(initValue);
    }
  }, [getPreferenceValue, options]);

  const handleChange = (e) => {
    const selectedValue = e.value;
    setValue(selectedValue);
    savePrefs(selectedValue.preferenceValue, prefsCodes.privateInvoiceAddress);
  };

  return (
    <InputWrapper label={t('Address')} footerContent={value?.address}>
      <Dropdown
        value={value}
        options={options}
        optionLabel="name"
        scrollHeight={dropdownScrollHeight()}
        onChange={handleChange}
        ariaLabel={t('Select address')}
      />
    </InputWrapper>
  );
};

export default PrivateInvoiceAddress;
