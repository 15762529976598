import React, { useCallback } from 'react';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import { useNotesContext } from '../../hooks/useNotesContext';
import { t } from '../../../../../../service/localization/i18n';
import { debounce } from 'lodash';
import cx from 'classnames';
import './NotesListHeader.scss';

const NotesListHeader = React.forwardRef((_, ref) => {
  const {
    notesState: { searchQuery },
    setNotesState,
    onSearch
  } = useNotesContext();

  const scrollToTop = () => {
    const scrollContainer = ref?.current;
    if (scrollContainer) scrollContainer.scrollTo({ top: 0 });
  };

  const debouncedSearch = useCallback(
    debounce((value) => onSearch(value), 1000),
    []
  );
  const debouncedScroll = debounce(scrollToTop, 900);

  const onSearchNotes = (value) => {
    debouncedScroll();
    debouncedSearch(value);
    setNotesState((prevState) => ({ ...prevState, searchQuery: value }));
  };

  const onClearInput = () => {
    onSearch('');
    setNotesState((prevState) => ({ ...prevState, searchQuery: '' }));
    scrollToTop();
  };

  const onAddNote = () => {
    setNotesState((prevState) => ({ ...prevState, noteDialog: { showDialog: true, note: undefined } }));
  };

  return (
    <div className="notes-list-header-container" style={{ paddingLeft: '0' }}>
      <div className="flex w-full md:pl-0 pr-3 gap-0 md:gap-3">
        <div className="notes-list-header flex align-items-center justify-content-start w-full" style={{ paddingLeft: '2rem' }}>
          <Button
            className="min-w-max hidden md:flex"
            label={t('Add_Note')}
            icon="pi pi-plus"
            style={{ height: '40px', marginRight: '1rem' }}
            onClick={onAddNote}
          />

          <Button className="flex md:hidden" icon="pi pi-plus" style={{ height: '40px', width: '40px', marginRight: '1rem' }} onClick={onAddNote} />

          <span className={cx('p-input-icon-left w-full', searchQuery ? 'p-input-icon-right' : '')} style={{ maxWidth: '50rem' }}>
            <i className="pi pi-search" />
            <InputText
              className="w-full"
              autoFocus
              value={searchQuery}
              placeholder={t('Search_for_notes')}
              onChange={(e) => onSearchNotes(e.target.value)}
            />
            {searchQuery && <i className="pi pi-times pointer" onClick={onClearInput} />}
          </span>
        </div>
      </div>
    </div>
  );
});

export default NotesListHeader;
