import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import MenuLayout from './MenuLayout';
import MenuItemRender from './MenuItemRender';
import GlobalSearch from '../GlobalSearch';

import { addNewPrivate } from '../../../claims/helpers/addNewInvoice';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { elementIDs } from '../../../config/elementIDsConfig';
import { t } from '../../../../service/localization/i18n';

const CreatePrivateInvoice = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const items = useMemo(() => {
    const items = [
      {
        label: <div className="font-bold">{`Create Private Invoice`}</div>,
        items: [
          {
            id: elementIDs.createPrivateInvoiceButton,
            label: t('Patient.1'),
            icon: 'pi pi-plus',
            command: () => addNewPrivate({ history }),
            template: (item) => <MenuItemRender item={item} />
          },
          {
            id: 'third_party',
            label: t('third_party'),
            icon: 'pi pi-plus',
            command: () => dispatch(setToastMessage({ type: 'info', message: 'Future Release', lifeTime: 3000 })),
            template: (item) => <MenuItemRender item={item} />
          }
        ]
      }
    ];

    items.push({
      template: () => (
        <div className="pt-3 pb-2 px-2">
          <GlobalSearch name="private" placeholder="Search private Invoice" />
        </div>
      )
    });

    return items;
  }, [dispatch, history]);

  return <MenuLayout model={items} />;
};

export default CreatePrivateInvoice;
