import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Tooltip } from 'primereact/tooltip';
import { AutoComplete } from 'primereact/autocomplete';

import HighlightSearchQuery from '../../../../components/Misc/HighlightSearchQuery/HighlightSearchQuery';

import { getPatients, getClaims } from '../../../../service/Lookup';
import { formatPatientInfoForClaims } from '../../../patients/helpers/formatPatientInfoForClaims';
import { viewPatientProfile } from '../../../patients/components/Patients/helpers/rowActionsMenuItems';
import { patientFullNameWithAge } from '../../../utils/patientFullName';
import { noResultsError } from '../../../common/components/inputInfoMessages';
import { onClaimViewClick } from '../../../claims/helpers/rowActions';
import { patientPickerPageSize } from '../../../config/defaultValuesConfig';
import { formatted, updateClaimList, updateClaimsState } from '../../../claims/actions/claims.action.creators';
import { t } from '../../../../service/localization/i18n';
import classes from './GlobalSearch.module.scss';
import moment from 'moment';
import cx from 'classnames';

// interface GlobalSearchProps {
//   name: 'patient' | 'claim' | 'wsbcr' | 'private';
//   placeholder: string;
// }

const invoiceTypes = {
  claim: 'Teleplan',
  private: 'Private',
  wsbcr: 'WSBC Report'
};

const GlobalSearch = ({ name, placeholder }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [query, setQuery] = useState('');
  const [isSuggestionsFound, setIsSuggestionsFound] = useState(true);
  const globalSearchRef = useRef(null);
  const mobileView = true;

  const fetchPatients = (searchQuery) => {
    setQuery(searchQuery);
    setIsFetching(true);

    switch (name) {
      // get patients
      case 'patient':
        getPatients(searchQuery).then((patients) => {
          setIsFetching(false);
          const patientsList = patients?.patientList?.map((x) => formatPatientInfoForClaims(x));
          patientsList?.length > 0 ? setIsSuggestionsFound(true) : setIsSuggestionsFound(false);
          setSuggestions(patientsList);
        });
        break;

      // get claims
      case 'claim':
      case 'private':
      case 'wsbcr':
        const query = { freetext: searchQuery, InvoiceType: invoiceTypes[name] };
        getClaims(user.details.DCNGuid, query, dispatch, patientPickerPageSize).then((claims) => {
          setIsFetching(false);
          claims?.length > 0 ? setIsSuggestionsFound(true) : setIsSuggestionsFound(false);
          setSuggestions(claims);
        });
        break;

      default:
        break;
    }
  };

  const onChange = (e) => {
    setValue(e.target.value);
    e.target.value.length === 0 && !isSuggestionsFound && setIsSuggestionsFound(true);
  };

  const onSelect = (e) => {
    switch (name) {
      // on select patient
      case 'patient':
        viewPatientProfile(e.value, history);
        break;

      // on select claim
      case 'claim':
      case 'private':
      case 'wsbcr':
        const formattedValue = formatted([e.value])[0];
        dispatch(updateClaimList([formattedValue])); // Update state to correctly display pagination of claims ViewPanel
        dispatch(
          updateClaimsState({
            page: 1,
            pageSize: 1,
            totalPages: 1,
            totalRecords: 1,
            totalRecordsAlter: 1
          })
        ); // Update state to correctly display pagination of claims ViewPanel
        onClaimViewClick(formattedValue, history);
        break;

      default:
        break;
    }
  };

  const clearInput = () => {
    setValue('');
    globalSearchRef?.current?.inputRef?.current?.focus();
    setIsSuggestionsFound(true);
  };

  const formatItemTemplate = (data) => {
    if (name === 'patient') {
      return (
        <>
          <div>
            <HighlightSearchQuery query={query} text={patientFullNameWithAge(data)} />
          </div>
          <div className="flex">
            <span className="pr-2">{`${t('Day_of_birthday.1')}:`}</span>
            <HighlightSearchQuery query={query} text={data.DOB} />
          </div>
          <div className="flex">
            <span className="pr-2">{`${t('PHN')}:`}</span>
            <HighlightSearchQuery query={query} text={data.PHN} />
          </div>
        </>
      );
    }

    if (name === 'claim' || name === 'private' || name === 'wsbcr') {
      const recordNum = data.SequenceNum ? `${data.RecordNo} (${t('Sequence.1').toLowerCase()}.# ${data.SequenceNum})` : data.RecordNo;

      return (
        <>
          <div className="flex">
            <span className="pr-2">
              <HighlightSearchQuery query={query} text={`${recordNum},`} />
            </span>
            <HighlightSearchQuery query={query} text={patientFullNameWithAge(data)} />
          </div>

          <div className="flex">
            <span className="pr-2">{`${t('Day_of_birthday.1')}:`}</span>
            <HighlightSearchQuery query={query} text={moment(data?.BirthDay).format('MM/DD/YYYY')} />
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className={mobileView ? classes.inputRootMobile : classes.inputRootDesktop}>
        <i className={cx('pi pi-search ml-2', classes.searchIcon)} />

        <AutoComplete
          id="globalPatientsSearch"
          className={cx('w-full', mobileView ? '' : classes.searchbarDesktop)}
          inputClassName={mobileView ? classes.searchbarInputMobile : classes.searchbarInputDesktop}
          ref={globalSearchRef}
          value={value}
          suggestions={suggestions}
          placeholder={placeholder || 'Search'}
          autoHighlight
          field={name === 'patient' ? 'label' : 'RecordNo'}
          dropdownMode="current"
          scrollHeight={500}
          onChange={onChange}
          onSelect={onSelect}
          completeMethod={(e) => fetchPatients(e.query)}
          itemTemplate={formatItemTemplate}
        />

        {value?.length > 0 && (
          <>
            <Tooltip target=".clearIconTooltip" position="top" />
            <div
              className={cx('clearIconTooltip', mobileView ? classes.clearIconWrapMobile : classes.clearIconWrapDesktop)}
              data-pr-tooltip={t('Clear')}
              onClick={clearInput}
            >
              {!isFetching && <i className={cx('pi pi-times mr-2', mobileView ? classes.clearIconMobile : classes.clearIconDesktop)} />}
            </div>
          </>
        )}
      </div>

      {!isSuggestionsFound && <div className="my-1 ml-4">{noResultsError}</div>}
    </>
  );
};

export default GlobalSearch;
