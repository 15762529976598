import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Prompt } from 'react-router-dom';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import CommonInputField from '../../../common/components/CommonInputField';
import PageTitle from '../../../../components/Layouts/Titles/PageTitle';
import { getApiBase } from '../../../../config';
import { t } from '../../../../service/localization/i18n';

const ClinicPreferences = () => {
  const history = useHistory();
  const clinic = useSelector((state) => state.clinic.details);
  const [isDirty] = useState(false);

  const onCancel = () => {
    // dispatch(setTheme(prevTheme));
    history.goBack();
  };

  const emptyTemplate = <p className="m-0">{t('Drag_and_drop_files_to_here_to_upload')}</p>;

  const promptMessage = () => {
    return JSON.stringify({
      isDirty,
      data: {}, //add "data" for callback params for prompt accept action
      name: 'clinicPreferences' //add "name" to check which form should be nulled (see customPrompt for nulling)
    });
  };

  const actionButtonst = () => {
    if (isDirty) {
      return (
        <>
          <Button
            label={t('Save')}
            flex
            className="w-full"
            // loading={savingPreferences}
            //  onClick={onSave}
          />
          <Button className="p-button-text w-full" label={t('Cancel')} onClick={onCancel} />
        </>
      );
    }

    return <Button className="w-full" label={t('Close')} onClick={onCancel} />;
  };

  return (
    <div className="flex justify-content-center p-3">
      <div className="card">
        <Prompt when={isDirty} message={promptMessage} />
        <div className="mb-3">
          <PageTitle>{t('Clinic_Preferences')}</PageTitle>
        </div>

        <CommonInputField label={t('Logotype')} style={{ width: 'fit-content' }}>
          <FileUpload
            name="clinicLogo"
            url={`${getApiBase()}/logo/${clinic.DCNGuid}`}
            accept="image/*"
            maxFileSize={10000000} // File size - 10 MB
            emptyTemplate={emptyTemplate}
          />
        </CommonInputField>

        {/* Action buttons */}
        <div className="flex mt-4 p-0">{actionButtonst()}</div>
      </div>
    </div>
  );
};

export default ClinicPreferences;
