import React, { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const RouteTrackerContext = createContext();

export const RouteTrackerProvider = ({ children }) => {
  const location = useLocation();
  const [previousUrl, setPreviousUrl] = useState('');
  const [currentUrl, setCurrentUrl] = useState(location.pathname);

  useEffect(() => {
    setPreviousUrl(currentUrl); // Set the previous URL before updating current one
    setCurrentUrl(location.pathname); // Update current URL
  }, [location]);

  return (
    <RouteTrackerContext.Provider value={{ previousUrl, currentUrl }}>
      {children}
    </RouteTrackerContext.Provider>
  );
};
