import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { InputWrapper } from '../../../../../components/Inputs';
import { Dropdown } from 'primereact/dropdown';
import { dropdownScrollHeight } from '../../../../config/stylesConfig';
import { prefsCodes } from '../../../../config/prefsCodesConfig';
import { usePrivateInvoicePrefs } from '../hooks/usePrivateInvoicePrefs';
import { getPractitionerNameWithSpec } from '../../../../utils/getPractitioner';
import { t } from '../../../../../service/localization/i18n';

const PrivateInvoicePhone = () => {
  const clinic = useSelector((state) => state.clinic.details);
  const members = useSelector((state) => state.clinic.members);
  const { savePrefs, getPreferenceValue } = usePrivateInvoicePrefs();

  const options = useMemo(() => {
    const practitioners =
      members?.map((member) => ({
        name: getPractitionerNameWithSpec(member),
        phone: member.Phone,
        preferenceValue: member.UserGuid
      })) || [];

    return [
      { name: 'None', phone: '', preferenceValue: '' },
      { name: 'Clinic', phone: clinic?.Phone || '', preferenceValue: clinic?.DCNGuid },
      ...practitioners
    ];
  }, [clinic, members]);

  const [value, setValue] = useState(options[0]);

  useEffect(() => {
    const prefsValue = getPreferenceValue(prefsCodes.privateInvoicePhone);

    if (!prefsValue) {
      setValue(options[0]);
    } else {
      const initValue = options.find((option) => option.preferenceValue === prefsValue.value) || options[0];
      setValue(initValue);
    }
  }, [getPreferenceValue, options]);

  const onChange = (e) => {
    const selectedValue = e.value;
    setValue(selectedValue);
    savePrefs(selectedValue.preferenceValue, prefsCodes.privateInvoicePhone);
  };

  return (
    <InputWrapper label={t('Phone')} footerContent={value.phone}>
      <Dropdown
        value={value}
        options={options}
        optionLabel="name"
        scrollHeight={dropdownScrollHeight()}
        onChange={onChange}
        ariaLabel={t('Select phone')}
      />
    </InputWrapper>
  );
};

export default PrivateInvoicePhone;
