import React, { useRef } from 'react';
import Dialogs from './modules/Dialogs/Dialogs';
import NotesProvider from './modules/NotesProvider/NotesProvider';
import NotesListHeader from './modules/NotesListHeader/NotesListHeader';
import NotesList from './modules/NotesList/NotesList';

// interface {
//   notesList: {
//     Title: string,
//     Comments: string,
//     NoteDate: Date,
//     Author: string,
//     NoteGuid: string,
//     EntityGuid: string, // PatientGuid for patient notes or InvoiceGuid for invoices notes
//     ParentGuid: string,
//     DCNGuid: string,
//     DoctorGuid: string,
//     Thread?: string,
//     Adressee?: any,
//   }[]
//   notesListRootStyles?: { [key: string]: string }
//   page: number, // Need for correct API requests for infinit scroll
//   totalPages: number, // Need for correct API requests for infinit scroll
//   entityGuid: string, // PatientGuid for patient notes or InvoiceGuid for invoices notes
//   onCreateNote: (note) => void
//   onEditNote: (note) => void
//   onDeleteNote: (patientGuid) => void
//   onSearch: (query: string) => void // Get notes on search
//   onScroll: (e: { page: number, totalPages: number, requestParams: any }) => void // Get notes on infinit scrolling
// }

const Notes = (props) => {
  const notesWrapRef = useRef(null);

  const { renderHeader = true } = props;

  return (
    <NotesProvider {...props}>
      <div className="flex flex-column gap-3">
        {renderHeader && <NotesListHeader ref={notesWrapRef} />}
        <NotesList ref={notesWrapRef} />
      </div>
      <Dialogs />
    </NotesProvider>
  );
};

export default Notes;
